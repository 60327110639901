import { TypographyProps } from '@mui/material/Typography';
import {
  GridCellParams,
  GridColDef,
  GridValueGetterParams,
  GridValueSetterParams,
} from '@mui/x-data-grid-pro';
import {
  IAggregationFunction,
  ICategoryResponse,
  IFieldData,
  IFieldResponse,
  IFieldValue,
  IPrimitiveHolderResponse,
  ITagResponse,
  UUID,
} from '@warden/warden-common/src/gen/api';
import { isTextPartialMatch } from '@warden/warden-common/src/shared/filters';
import { RecursiveKeys } from '@warden/warden-common/src/shared/json.types';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { memo, useTransition } from 'react';
import { useNavigate } from 'react-router-dom';

import { WardenBasePrimitive } from '../components/primitives/types';
import { ISelectOption } from '../components/WardenTableRhRail/ColumnValuePair/SingleSelectColumn';

export function useNavigateTimeout(): (path: string) => void {
  const navigate = useNavigate();
  const [, startTransition] = useTransition();
  return (path: string) =>
    startTransition(() => {
      setTimeout(() => {
        navigate(path);
      }, 20);
    });
}

export const genericMemo: <T>(component: T) => T = memo;

export function nestedColGet(params: GridValueGetterParams) {
  return _.get(params.row, params.field);
}

export function nestedColGetCatField(
  catField: IFieldResponse,
  params: GridValueGetterParams
) {
  const data =
    _.get(params.row, `${params.field}.value`) === null
      ? undefined
      : _.get(params.row, `${params.field}.value`) ??
        _.get(params.row, params.field); // when grouping by a category field, the value somehow doesn't show up

  if (catField.fieldData.fieldType == 'monetary') {
    if (!data) return undefined;
    // for monetary fields we need to also return the `fieldType` such that it works for both cat attributes and
    // primitive fields
    return _.get(params.row, `${params.field}`);
  }
  if (catField.fieldData.fieldType == 'date') {
    if (!data) return undefined;
    return data;
  }
  if (catField.fieldData.fieldType == 'dateTime') {
    if (!data) return undefined;
    return data;
  }
  return data;
}

export function nestedColSetCatField(
  catField: IFieldResponse,
  params: GridValueSetterParams
) {
  const existingData = _.get(params.row, `categoryFields.${catField.id}`);
  if (existingData === undefined) {
    // cant know what type of cat field it is, so just return nothing
    return undefined;
  }
  const newRow = _.cloneDeep(params.row);
  return _.set(newRow, `categoryFields.${catField.id}.value`, params.value);
}

export type IGridColDef<T extends WardenBasePrimitive = any> = GridColDef<T> & {
  field: RecursiveKeys<T>;
  type?:
    | IFieldData['fieldType']
    | 'category'
    | 'wardenEntity'
    | 'wardenEntityArray'
    | 'checkbox-group'
    | 'actions';
  filterFn?: 'exactMatch' | 'partialMatch';
  hidden?: boolean;
  hiddenRR?: boolean; // hidden in the right rail
  categoryFieldId?: UUID;
  defaultFilter?: boolean;
  disableFilter?: boolean;
  disableGroupBy?: boolean;
  filterChipFormat?: (params: {
    row: T;
    field: RecursiveKeys<T>;
  }) => string | undefined;
  formatForFilter?: (params: {
    row: T;
    field: RecursiveKeys<T>;
  }) => string | undefined;
  aggregationFunction?: {
    name: IAggregationFunction;
    sxName?: TypographyProps['sx'];
    aggregateEntireRow?: boolean;
    renderFn?: React.ReactNode;
  };
  aggregationHeaderName?: string;
  aggregationResultAsNumber?: (value: any) => number;
  groupedValueGetter?: (params: GridValueGetterParams) => any;
  options?: ISelectOption[];
  attributeDef?: IFieldData;
  renderRR?: (params: {
    row: T;
    column: IGridColDef;
    value: any;
    onSubmit: (value: IFieldValue) => void;
  }) => React.ReactNode;
  columnClassName?: (params: GridCellParams<IFieldValue>) => string;
};

export function filterRecursiveStructure(
  filterStr: string,
  dataMap: Record<UUID, { id: UUID; content: string; children: UUID[] }>,
  rootLevel: UUID[]
): Set<UUID> {
  // create cloned object with id, path and children
  const resultSet = new Set<UUID>();

  for (const _rootItemId of rootLevel ?? []) {
    const item = dataMap[_rootItemId];
    const visibleChildren = filterRecursiveStructure(
      filterStr,
      dataMap,
      item.children
    );
    const currentCategoryMatches = isTextPartialMatch(item.content, filterStr);
    if (currentCategoryMatches || visibleChildren.size > 0) {
      resultSet.add(item.id);
    }
    for (const childId of visibleChildren) {
      resultSet.add(childId);
    }
  }

  return resultSet;
}

export function* iterateOverTag(
  tagId: UUID,
  tagsMap: Record<UUID, ITagResponse>,
  depth = 0
): Generator<[ITagResponse, number]> {
  yield [tagsMap[tagId], depth];
  for (const child of tagsMap[tagId].childTags ?? []) {
    yield* iterateOverTag(child.id, tagsMap, depth + 1);
  }
}

export function traverseCategoryAndGetFields(
  categoryId: UUID | undefined,
  categories: ICategoryResponse[],
  primitiveHolders: IPrimitiveHolderResponse[]
) {
  const fields = primitiveHolders.map((ph) => ph.fields).flat();
  let currentCategoryId = categoryId;
  while (currentCategoryId) {
    const currentCategory = categories.find((c) => c.id === currentCategoryId);
    fields.push(...(currentCategory?.fields ?? []));
    currentCategoryId = currentCategory?.parentCategory?.id;
  }
  return fields;
}

export function getCategoryFieldValueByName({
  category,
  fieldName,
  categoryFields,
}: {
  category: ICategoryResponse;
  fieldName: string;
  categoryFields?: Record<UUID, IFieldValue>;
}) {
  const field = category.fields.find(
    (f) => f.label.toLowerCase() === fieldName.toLowerCase()
  );

  if (!field?.id || !categoryFields) {
    return undefined;
  }
  return categoryFields[field.id]?.value;
}

export function invariantWarning(condition: boolean, ...message: any) {
  if (!condition) {
    console.warn(...message);
  }
}

export function isStaging(): boolean {
  return import.meta.env.VITE_API_ENV === 'staging';
}

export function isProd(): boolean {
  return import.meta.env.VITE_API_ENV === 'production';
}

export function isDevelopment(): boolean {
  return (
    window.location.href.includes('localhost') ||
    window.location.href.includes('127.0.0.1')
  );
}

export function isE2E(): boolean {
  return import.meta.env.VITE_IS_IN_E2E === 'true';
}

export function isNotProduction(): boolean {
  return isStaging() || isDevelopment();
}

export enum EAppMode {
  Pro = 'pro',
  Vault = 'vault',
}

export function getAppMode(): EAppMode {
  if (import.meta.env.VITE_NODE_ENV === 'test') {
    return EAppMode.Vault;
  } else if (import.meta.env.DEV) {
    if (import.meta.env.VITE_MODE === 'legacy') {
      return EAppMode.Vault;
    } else {
      return EAppMode.Pro;
    }
  } else {
    if (window.location.hostname.startsWith('pro')) {
      return EAppMode.Pro;
    } else {
      return EAppMode.Vault;
    }
  }
}

export function isProAppMode() {
  return getAppMode() === EAppMode.Pro;
}

export function getGoogleCalendarUrl(
  name: string,
  details: string,
  dueDate: Date
): string {
  const baseUrl = 'calendar.google.com/calendar/render?action=TEMPLATE';
  const urlParams = new URLSearchParams();
  urlParams.append('text', name);
  urlParams.append('details', details);
  urlParams.append(
    'dates',
    `${DateTime.fromJSDate(dueDate).toFormat('yyyyMMdd')}/${DateTime.fromJSDate(
      dueDate
    ).toFormat('yyyyMMdd')}`
  );

  return `https://${baseUrl}&${urlParams.toString()}`;
}

export function replaceLast(
  str: string,
  search: string,
  replacement: string,
  fromIndex?: number
): string {
  const index = str.lastIndexOf(search, fromIndex);
  if (index === -1) {
    return str;
  }
  return str.slice(0, index) + replacement + str.slice(index + search.length);
}

export async function saveJsonAsFile(jsonData: any, filename: string) {
  const contentType = 'application/json;charset=utf-8;';
  if ((window.navigator as any)?.msSaveOrOpenBlob) {
    const blob = new Blob(
      [decodeURIComponent(encodeURI(JSON.stringify(jsonData)))],
      { type: contentType }
    );
    (navigator as any).msSaveOrOpenBlob(blob, filename);
  } else {
    const a = document.createElement('a');
    a.download = filename;
    a.href =
      'data:' +
      contentType +
      ',' +
      encodeURIComponent(JSON.stringify(jsonData));
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}
